// THEME COLORS
$mbsc-ios-accent: $green-100;

$mbsc-calendar-accent-light: $green-80;

$holiday-cell-background-color: #f6d6d980;

// FONT SIZE CHANGES
.mbsc-font {
	font-family: inherit !important;
	font-size: inherit !important;
}

.mbsc-schedule-time {
	font-size: 1rem !important;
}

.mbsc-schedule-time-indicator-time {
	font-size: 1rem !important;
}

.mbsc-calendar-text {
	font-size: 1rem !important;
	margin-left: 1px !important;
	margin-right: 5px !important;
}

.mbsc-calendar-label-text {
	font-size: 0.9rem !important;
}

// BACKGROUND COLOR CHANGES
.mbsc-schedule-wrapper {
	background-color: $white !important;
}

.mbsc-calendar-header {
	background-color: $white !important;
}

// BIG MONTH CALENDAR BORDERS
mbsc-eventcalendar .mbsc-calendar-row {
	border-left: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
}

// AGENDA VIEW
.mbsc-list-header {
	// Titles of days
	font-weight: bold !important;
	font-size: 1rem !important;
}

.mbsc-event-text {
	// Event titles
	font-weight: bold !important;
}

mbsc-month-view[ng-reflect-calendar-type='week'] mbsc-calendar-day {
	// Weekdays in header
	border: none !important;
	margin-bottom: 4px !important;
}

mbsc-month-view[ng-reflect-calendar-type='week'] .mbsc-calendar-row {
	// Wrapper of weekdays
	border: none !important;
}

// Remove grey background from weekdays in Agenda and Month view
mbsc-calendar-weekdays {
	background-color: inherit !important;
}

//OTHER DETAILS
.mbsc-datepicker-inline {
	//Remove the top border from month calendar
	border-top: none !important;
}

.mbsc-calendar-button {
	// Override the accent color in the header buttons of calendars
	color: $black !important;
	line-height: inherit !important;
}

.mbsc-icon-button {
	//Remove default sizing of the previous and next buttons in calendar headers
	padding: 0px !important;
	height: auto !important;
	width: auto !important;
}

//Remove scroll bar from All day element, and add fake hidden one to keep items aligned
//Similar method is used by Mobiscroll in the main header section
.mbsc-schedule-all-day-wrapper {
	overflow-y: auto !important;
	height: auto !important;
	max-height: 5em !important;
}
.mbsc-schedule-all-day {
	min-height: 3em !important;
}
.mbsc-schedule-all-day-group-wrapper {
	flex-direction: row !important;
}
.mbsc-schedule-all-day-group-wrapper::after {
	//Create hidden scroll bar, so that the day elements align properly
	content: '';
	display: block;
	overflow-y: scroll;
	visibility: hidden;
}

mbsc-input {
	height: 100%;

	&::before,
	&::after {
		content: none !important;
	}
	label {
		display: none !important;
	}
	span {
		height: 100%;

		input {
			height: 100% !important;
			padding: 0 !important;
		}
	}
}

// Style button group's buttons similar to our success buttons
mbsc-segmented-group {
	border-radius: 3px !important;
	padding: 0 !important;

	mbsc-button {
		min-width: 44px !important;
		min-height: 44px !important;

		&.mbsc-selected {
			color: $white !important;
		}
	}

	.mbsc-ios.mbsc-segmented-selectbox {
		padding: 0px !important;

		.mbsc-ios.mbsc-segmented-selectbox-inner {
			border-radius: 3px !important;
			background-color: $green-80 !important;
			padding: 10px 15px !important;
		}
	}
}

.holiday-allhours-background {
	background: $holiday-cell-background-color !important;
}

.holiday-monthcalendar-label {
	.mbsc-calendar-day-text {
		color: $red-100 !important;
	}
}

.special-day-daytemplate {
	background: $holiday-cell-background-color !important;
}

.inline-block {
	display: inline-block;
}

.selected-date-circle {
	background: $green-80;
	color: $white;
	display: inline-block;
	height: 1.625em;
	width: 1.625em;
	line-height: 1.625em;
	border-radius: 50%;
	text-align: center;
	font-size: 1em;
	font-weight: 400;
}

// Schedule event min height and button size
.mbsc-schedule-event {
	padding: 0 !important;
	min-height: 1.5em !important;
	.dropdown {
		padding: 0 !important;
		min-width: 2em !important;
		.btn {
			padding: 0 !important;
			min-width: 2em !important;
		}
		button {
			min-width: 10% !important;
			min-height: 10% !important;
			padding: 0 !important;
		}
	}
}

// Hide event buffer
.mbsc-schedule-event-buffer {
	display: none !important;
}

// Current time indicator overrides
.mbsc-schedule-time-indicator-day::before {
	width: 100% !important;
	border-radius: 0 !important;
	margin-left: 0 !important;
	height: 1px !important;
}

.mbsc-schedule-time-indicator-day::after {
	content: '';
	top: -7px;
	left: 0;
	border-width: 5px;
	border-color: transparent transparent transparent $green-80;
	border-style: solid;
	width: 0px;
	height: 0px;
	position: absolute;
}

.mbsc-schedule-time-indicator-x {
	border-bottom: 0 !important;
}

.mbsc-schedule-item,
.mbsc-schedule-time-wrapper {
	height: 2.375rem !important;
}

@media screen and (min-height: 1080px) and (max-resolution: 1dppx) {
	.mbsc-schedule-item,
	.mbsc-schedule-time-wrapper {
		height: 3.125rem !important;
	}
}

@media screen and (min-height: 2160px) and (max-resolution: 1.5dppx) {
	.mbsc-schedule-item,
	.mbsc-schedule-time-wrapper {
		height: 4.375rem !important;
	}
}

mbsc-select {
	.mbsc-ios.mbsc-textfield-outline {
		padding-left: 10px !important;
	}
	.mbsc-ios.mbsc-textfield-wrapper-outline,
	.mbsc-ios.mbsc-textfield-wrapper-box {
		margin: 0;
	}
	.mbsc-ios.mbsc-textfield-inner-box,
	.mbsc-ios.mbsc-textfield-inner-outline,
	.mbsc-textfield-inner-inline {
		height: 35px;
	}
	.mbsc-ios.mbsc-textfield-outline {
		overflow: hidden;
		margin-top: 2px;
		border: 1px solid #b6bec2;
		border-radius: var(--bs-border-radius);
	}
}
.mbsc-popup-body {
	border: 1px solid #b6bec2;
}
.mbsc-ios.mbsc-select-filter {
	height: 35px;
	input {
		padding-left: 10px !important;
	}
}
