// The purpose of this file is to override bootstrap classes that cannot be changed with variables.
// This file can also be used to define new classes that should exist in bootstrap but are not working for some reason

.bg-body-secondary {
	// should exists but doesnt
	background-color: $neutral-20 !important;
}

.text-bg-info,
.text-bg-success-badge {
	//https://getbootstrap.com/docs/5.3/helpers/color-background/
	// overriding dynamically created text color
	color: $white !important;
}

.nav-tabs .nav-link.active {
	border-bottom: 2px solid $green-80;
}
.nav-tabs .nav-link:not(.active):hover {
	background-color: $green-hover;
}
.nav-tabs .nav-link:not(.active):active {
	background-color: $green-focus;
}

.form-control:focus {
	box-shadow: none !important;
}

.min-h-100 {
	min-height: 100%;
}

// Dropdown
[ngbDropdownToggle] {
	&::after {
		content: none;
	}
}
[ngbDropdownItem] {
	padding: 10px;

	&:hover {
		background: $neutral-10;
	}
	&:active {
		background: $green-80;
	}
}
[ngbDropdownMenu] {
	@extend .shadow-sm;
	padding: 0;
}
