//Default overrides
.as-split-gutter {
	width: 27px !important;

	flex-basis: 27px !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: default !important;
	background-color: $white !important;

	&:hover {
		cursor: grab !important;
		background-color: $neutral-10 !important;
	}
	&:active {
		background-color: $neutral-20 !important;
		cursor: grabbing !important;
	}
}

.as-split-gutter-icon {
	background-image: none !important;
	display: block !important;
	height: 50px !important;
	width: 5px !important;
	border-radius: 3px;
	background-color: $neutral-50;
}

//Component spesific overrides
