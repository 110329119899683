formly-group {
	.grid {
		row-gap: 0px;
		column-gap: 10px;
	}

	.full-width {
		grid-column: span 12;
	}
}

formly-field {
	.form-label,
	.form-check-label {
		/* Make the required asteriks character red  */
		span {
			color: $red-100;
		}
	}
}

.select-container {
	width: 100%;
	position: relative;
	display: inline-block;
}

.warning-icon {
	position: absolute;
	top: 50%;
	right: 1.8em;
	transform: translateY(-50%);
	color: red;
	font-size: 1.2rem;
	pointer-events: none; /* Ei estä valintaa */
}
