/* Colors: */
$prussian-10: #98afbb;
$prussian-20: #849fae;
$prussian-30: #708fa0;
$prussian-40: #5e7f8f;
$prussian-50: #516d7b;
$prussian-60: #445b67;
$prussian-70: #395361;
$prussian-80: #3b505c;
$prussian-90: #23343d;
$prussian-100: #182830;
$black: #000000;
$black-1a: #0000001a;
$white: #ffffff;
$body: #e4e9eb;
$orange-100: #fe6d4b;
$orange-90: #fe7b5d;
$orange-80: #fe8a6f;
$orange-30: #fed3c9;
$orange-40: #fec4b6;
$orange-50: #feb5a4;
$orange-60: #fea793;
$orange-70: #fe9880;
$orange-20: #fee1da;
$orange-10: #fef0ed;
$green-100: #659a1f;
$green-90: #71ad23;
$green-80: #7ec027;
$green-70: #8bc63d;
$green-60: #98cd52;
$green-50: #a5d368;
$green-40: #b2d97d;
$green-20: #cbe6a9;
$green-30: #bfe093;
$green-10: #d8ecbe;
$green-hover: #7ec02726;
$green-focus: #7ec0274d;
$red-100: #d43644;
$red-90: #d84a56;
$red-80: #dc5e69;
$red-70: #e0727c;
$red-60: #e5868e;
$red-50: #e99aa1;
$red-40: #edaeb4;
$red-20: #f6d6d9;
$red-30: #f2c2c6;
$red-10: #faeaec;
$blue-100: #24a0ce;
$blue-90: #31addb;
$blue-80: #48b6df;
$blue-70: #5fbfe3;
$blue-60: #76c8e7;
$blue-50: #8cd1eb;
$blue-40: #a3daef;
$blue-20: #d1ecf7;
$blue-30: #bae3f3;
$blue-10: #e8f5fb;
$brand-100: #ffc908;
$brand-90: #ffcf30;
$brand-80: #ffd44f;
$brand-70: #ffd868;
$brand-60: #ffdd7e;
$brand-50: #ffe292;
$brand-40: #ffe8a7;
$brand-30: #ffeebc;
$brand-20: #fff3d2;
$brand-10: #fff9e7;
$neutral-10: #e6e9ea;
$neutral-20: #ced3d6;
$neutral-30: #b6bec2;
$neutral-40: #9ea8ae;
$neutral-50: #85939a;
$neutral-60: #6e7d84;
$neutral-70: #5a666c;
$neutral-80: #464f54;
$neutral-90: #32383c;
$neutral-100: #1e2224;
$secondary-100: #212121;
$secondary-90: #373737;
$secondary-80: #4d4d4d;
$secondary-70: #646464;
$secondary-60: #7a7a7a;
$secondary-50: #909090;
$secondary-40: #a6a6a6;
$secondary-30: #bcbcbc;
$secondary-20: #d3d3d3;
$secondary-10: #e9e9e9;
