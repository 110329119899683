//Global button overrides
button {
	padding: 0;
	// WCAG 2.5.5
	min-width: 44px;
	min-height: 44px;
}

.btn {
	border: none;
	border-radius: 3px;
	padding: 10px 15px;
	padding: i {
		background-color: transparent;
	}
	&:disabled {
		color: $neutral-50 !important; //use important to override button spesific color
		pointer-events: none;
	}
}

//Overrides to bootstrap button classes
/*
There is probably a fancy way to map correct colors to the buttons in a function,
instead of manually applying them. It looks like colored buttons follow the same
pattern with different states (hover, active etc). Research more about this when styles have been locked
and using something like that becomes more useful. 
*/
.btn-success {
	color: $white !important; //use important to use same color in different states (overriding bootstrap colors)
	&:hover {
		background-color: $green-60;
	}
	&:active {
		background-color: $green-40;
	}
	&:disabled {
		background-color: $green-20;
	}
}

.btn-secondary {
	background-color: $neutral-10;
	color: $neutral-100 !important;
	&:hover {
		background-color: $neutral-20;
	}
	&:active {
		background-color: $neutral-30;
	}
	&:disabled {
		background-color: $neutral-10;
	}
}

.btn-danger {
	background-color: $red-80;
	color: white !important;
	&:hover {
		background-color: $red-60 !important;
	}
	&:active {
		background-color: $red-40 !important;
	}
	&:disabled {
		background-color: $red-20 !important;
	}
}

//own bootstrap buttons
.kk-btn-round {
	padding: 0px;
	height: 37px;
	width: 37px;
	border-radius: 37px;
	box-shadow: 0px 1px 4px #00000040;
	&.yellow {
		background-color: var(--bs-yellow);
	}
}

.kk-btn-transparent {
	color: $neutral-100;
	border: none;
	background-color: transparent;
	box-shadow: none;
	opacity: 1;
	&:hover {
		opacity: 0.75;
	}
	&:active {
		background-color: $neutral-30;
	}
}

.kk-btn-prussian {
	color: $neutral-20 !important;
	border: none;
	background-color: $prussian-90;
	box-shadow: none;
	&:hover {
		background-color: $prussian-60;
		color: $white !important;
	}
	&:active {
		background-color: $prussian-80 !important;
	}
}

.kk-btn-hover-border-success {
	&:hover {
		border-color: $green-80 !important;
	}
}

.kk-btn-selected-border-success {
	border-color: $green-80 !important;
}

.btn-brand {
	background-color: $brand-90;
	&:hover {
		background-color: $brand-50;
	}
	&:active {
		background-color: $brand-60 !important;
	}
}

.btn-elevated {
	box-shadow: 0 1px 3px $neutral-50;
	&:hover {
		background-color: $neutral-10;
	}
	&:active {
		background-color: $neutral-20 !important;
	}
}
