//1. import libraries
// docs: https://getbootstrap.com/docs/5.0/customize/sass/

// Font Awesome
@import '@lemonsoft-artifacts/font-awesome/css/all.min.css';

//2. import colors
@import './styles/colors.scss';

//3. Include overrides to bootstrap variables
@import './styles/bs-overrides.scss';

//4. import bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';

//5. Add additional custom code here (overrides to bootstrap classes or global custom classes)
@import './styles/material.scss';
@import './styles/bs-utilities.scss';
@import './styles/typography.scss';
@import './styles/button.scss';
@import './styles/input.scss';
@import './styles/gridster-overrides.scss';
@import './styles/mobiscroll-overrides.scss';
@import './styles/splitter.scss';
@import './styles/shadows.scss';
@import './styles/form.scss';

@import '@mobiscroll/angular/dist/css/mobiscroll.scss';
