.drop-shadow-x {
	filter: drop-shadow(2px 0px 2px $black-1a);
}

.drop-shadow-y {
	filter: drop-shadow(0px 1px 2px $black-1a);
}

.box-shadow-top {
	box-shadow:  0px -2px 5px $black-1a;
}
