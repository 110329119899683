// Theme overrides
$theme-colors: (
	'primary': $blue-100,
	'secondary': $neutral-50,
	'secondary-emphasis': $neutral-80,
	'success': $green-80,
	'success-subtle': $green-30,
	'info': $blue-100,
	'warning': $orange-100,
	'danger': $red-100,
	'light': $body,
	'dark': $prussian-90,
	'dark-subtle': $prussian-80,
	'primary-button': $brand-100,
	'secondary-button': $neutral-10,
	'white': $white,
	'success-badge': $green-80,
	'neutral-badge': $neutral-10,

	'prussian-10': $prussian-10,
	'prussian-20': $prussian-20,
	'prussian-30': $prussian-30,
	'prussian-40': $prussian-40,
	'prussian-50': $prussian-50,
	'prussian-60': $prussian-60,
	'prussian-70': $prussian-70,
	'prussian-80': $prussian-80,
	'prussian-90': $prussian-90,
	'prussian-100': $prussian-100,
	'orange-10': $orange-10,
	'orange-20': $orange-20,
	'orange-30': $orange-30,
	'orange-40': $orange-40,
	'orange-50': $orange-50,
	'orange-60': $orange-60,
	'orange-70': $orange-70,
	'orange-80': $orange-80,
	'orange-90': $orange-90,
	'orange-100': $orange-100,
	'green-10': $green-10,
	'green-20': $green-20,
	'green-30': $green-30,
	'green-40': $green-40,
	'green-50': $green-50,
	'green-60': $green-60,
	'green-70': $green-70,
	'green-80': $green-80,
	'green-90': $green-90,
	'green-100': $green-100,
	'red-10': $red-10,
	'red-20': $red-20,
	'red-30': $red-30,
	'red-40': $red-40,
	'red-50': $red-50,
	'red-60': $red-60,
	'red-70': $red-70,
	'red-80': $red-80,
	'red-90': $red-90,
	'red-100': $red-100,
	'blue-10': $blue-10,
	'blue-20': $blue-20,
	'blue-30': $blue-30,
	'blue-40': $blue-40,
	'blue-50': $blue-50,
	'blue-60': $blue-60,
	'blue-70': $blue-70,
	'blue-80': $blue-80,
	'blue-90': $blue-90,
	'blue-100': $blue-100,
	'brand-10': $brand-10,
	'brand-20': $brand-20,
	'brand-30': $brand-30,
	'brand-40': $brand-40,
	'brand-50': $brand-50,
	'brand-60': $brand-60,
	'brand-70': $brand-70,
	'brand-80': $brand-80,
	'brand-90': $brand-90,
	'brand-100': $brand-100,
	'neutral-10': $neutral-10,
	'neutral-20': $neutral-20,
	'neutral-30': $neutral-30,
	'neutral-40': $neutral-40,
	'neutral-50': $neutral-50,
	'neutral-60': $neutral-60,
	'neutral-70': $neutral-70,
	'neutral-80': $neutral-80,
	'neutral-90': $neutral-90,
	'neutral-100': $neutral-100,
	'secondary-10': $secondary-10,
	'secondary-20': $secondary-20,
	'secondary-30': $secondary-30,
	'secondary-40': $secondary-40,
	'secondary-50': $secondary-50,
	'secondary-60': $secondary-60,
	'secondary-70': $secondary-70,
	'secondary-80': $secondary-80,
	'secondary-90': $secondary-90,
	'secondary-100': $secondary-100,
);

$font-family-base: inherit;

// Background color override
$body-bg: $body;

// Utility overrides
$utilities: (
	'opacity': (
		property: opacity,
		values: (
			0: 0,
			25: 0.25,
			50: 0.5,
			75: 0.75,
			85: 0.85,
			100: 1,
		),
	),
);

// Enable css grid
$enable-grid-classes: false;
$enable-cssgrid: true;

// Overrides for bootstrap components in layout module
$navbar-toggler-font-size: inherit;

// Overrides for bootstrap button
$btn-font-size: inherit;
$btn-line-height: inherit;
$btn-color: inherit;

// Border radius
$border-radius: 3px;
$border-radius-lg: 3px;
// Badge
$badge-font-size: inherit;
$badge-font-weight: normal;

// Card
$card-border-width: 0;
.card {
	overflow: hidden; // Added so child element don't override border radius
	&:hover {
		outline: $green-80 solid 1px !important;
	}
}
//Form label
$form-label-margin-bottom: 1px;

// Form input
$input-color: black;

$input-bg: $white;
$input-border-color: $neutral-30;
$input-border-width: 1px;
$input-focus-border-color: $green-80;

$form-select-focus-box-shadow: none;

$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 320 512'><path d='M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z'/></svg>");

// Nav
// used in management form tabs
$nav-link-color: $neutral-100;
$nav-link-hover-color: $neutral-100;
$nav-tabs-link-active-color: $green-80;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-border-width: 0px;
$nav-tabs-border-color: $neutral-20;
$nav-link-focus-box-shadow: 10px 2px 5px #000000;

// Modal
$modal-content-bg: $white;
$modal-content-border-width: 0;

//list-group
$list-group-bg: $white;
$list-group-color: $neutral-100;

$list-group-active-bg: $green-30;
$list-group-action-active-bg: $green-30;

$list-group-active-color: $neutral-100;
$list-group-action-active-color: $neutral-100;

// Dropdown
$dropdown-bg: $white;
$dropdown-font-size: inherit;

//Spacing
$spacer: 0.7rem;

.remove-select-background select {
	background-image: none !important;
}

.modal {
	@media (min-width: 768px) {
		--bs-modal-width: 750px !important;
	}
}
// Checkbox
.form-check-input {
	margin-top: 2px !important;
	width: 16px !important;
	height: 16px !important;
	&:hover {
		cursor: pointer;
	}
}
.form-check-label {
	&:hover {
		cursor: pointer;
	}
}
.form-check {
	&:hover {
		cursor: pointer;
		.form-check-input {
			box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.2);
		}
	}
}
.input-group {
	.btn {
		padding: 6px 12px;
	}
}
