.form-check-input {
	background-color: $white;
	border: 1px solid $neutral-30;
	box-shadow: inset 2px 2px 1px #0000001a;

	&:active {
		background-color: $neutral-20;
		border-color: $neutral-30;
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='white'/></svg>");
	}

	&:checked {
		background-color: $green-80;
		border-color: $green-80;
	}
	&:focus {
		border-color: $green-80;
		box-shadow: inset 2px 2px 1px #0000001a, 0px 0px 2px $green-80;
	}
}
