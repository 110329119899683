html {
	font-family: 'Lato', sans-serif;
	font-size: 16px !important;
	line-height: 22px;
	font-weight: 400;
	color: $neutral-100;

	@include media-breakpoint-up(sm) {
		font-size: 14px !important;
		line-height: 20px;
	}
}
a {
	text-decoration: none;
	color: unset;
	&:hover {
		color: unset;
	}
}

h1,
h2,
h3,
h4,
p,
caption,
.page-title,
.content-title,
.subheading,
.subtitle,
.paragraph,
.subparagraph,
.caption {
	font-weight: inherit;
	margin: 0px;
}

h1,
.page-title {
	font-size: 1.71rem;
}
h2,
.h2,
.content-title {
	font-size: 1.4285rem;
}
h3,
.subheading {
	font-size: 1.29rem;
}
h4,
.subtitle {
	font-size: 1.14rem;
	color: #85939a;
}
p,
.paragraph {
	font-size: 1rem;
}
.subparagraph {
	font-size: 0.86rem;
}
caption,
.caption {
	font-weight: 400;
	color: $neutral-100;
}
.--bold {
	font-weight: 700 !important;
}

.font-italic {
	font-style: italic;
}

.text-small {
	font-size: 0.8em;
}

.mt-m2 {
	margin-top: -$spacer * 0.5 !important;
}

.hidden {
	display: none;
}

.emh-2 {
	height: 2em;
}
