.gridster-preview {
	// change bacground color of preview block, which shows the position of active item
	// when moving it in edit mode
	background: $green-30 !important;
}

gridster-item {
	//these have default background as white and we want it to be transparent
	// in order to achieve transparency during editing
	background: transparent !important;
}

.gridster-item-moving {
	//remove default box shadow from the items during moving
	box-shadow: none !important;
}
